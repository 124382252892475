@import "mixins"
.header
  @include flex
  height: 44px
  color: #fff
  padding: 0 10px
  background: var(--policies_headerBg)
  position: sticky
  top: var(--headerHeight)
  z-index: 3

  :global(.imageNext)
    margin-right: 12px

  h1
    margin-right: 10px

.dropdown
  margin-left: auto
  max-width: 186px
  flex: 1
  button
    color: #fff
