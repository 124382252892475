@import "mixins"
.container
  position: relative

.multipleSelector
  padding: 0 28px 0 10px
  height: 32px
  background: var(--ddl_buttonBg)
  position: relative
  box-shadow: inset 0 1px var(--ddl_buttonShadow)
  border: var(--ddl_buttonBorder)
  font-weight: normal
  text-align: left
  justify-content: normal
  color: rgb(var(--primaryColor))
  border-radius: var(--base_borderRadius)
  min-width: 100%
  text-transform: capitalize
  i,
  :global(.svg-icon)
    margin: 0 12px 0 0

  > span
    word-break: break-word
    @include flex

  &:after
    content: ""
    position: absolute
    width: 10px
    height: 5px
    min-width: 10px
    background: var(--icon-arrow) no-repeat
    top: calc(50% - 2px)
    right: 10px

.value
  -webkit-line-clamp: 2
  text-overflow: ellipsis
  overflow: hidden
  display: -webkit-box
  -webkit-box-orient: vertical
  line-height: 14px

.dropdownModal
  z-index: 1003
  width: 100%
  height: calc(100% - var(--headerHeight) - var(--menuHeight))
  position: fixed
  top: var(--headerHeight)
  left: 0
  right: 0
  bottom: 0
  @include flex
  justify-content: center
  // background: var(--backdropBg)
  > div
    position: fixed

.dropdownModal :global
  .modal
    max-width: 210px
    background: var(--ddl_listBg)
    border-radius: var(--base_borderRadius)
    height: auto
    max-height: 60%
    overflow: auto
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2)
    li
      @include flex
      height: 48px
      padding: 0 14px
      > i,
      :global(.svg-icon)
        margin-right: 8px
      &:not(:first-of-type)
        border-top: 1px solid var(--ddl_listBorder)

  .modal :global
    li.selected
      opacity: 1
      background: var(--ddl_listSelectedBg)

.dropdown
  position: absolute
  width: 100%
  background: var(--ddl_listBg)
  border-radius: var(--base_borderRadius)
  overflow: hidden
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2)
  margin-top: 3px
  z-index: 20
  li
    @include flex
    height: 36px
    font-size: 12px
    line-height: 14px
    opacity: 0.8
    color: #fff
    text-transform: capitalize
    padding: 0 10px
    font-weight: normal
    &:not(:first-of-type)
      border-top: 1px solid var(--ddl_listBorder)
    i,
    :global(.svg-icon)
      margin-right: 12px

.dropdown :global
  li.selected
    opacity: 1
    background: var(--ddl_listSelectedBg)

.dropdownLoader
  > span
    font-size: 15px !important
