@import "mixins"
.bonusesHeader
  composes: header from "../policies/policies-layout.module.sass"

.dropdown
  composes: dropdown from "../policies/policies-layout.module.sass"
  :global(.svg-icon)
    min-width: 24px
    svg
      width: 24px
      height: auto

.listItem
  -webkit-line-clamp: 2
  text-overflow: ellipsis
  overflow: hidden
  display: -webkit-box
  -webkit-box-orient: vertical
  word-wrap: break-word
  line-height: 16px

.content
  display: flex
  align-items: flex-start
  height: calc(100% - 70px)
